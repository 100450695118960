.dashboard-metrics {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
  padding: 0 20px;
}

.metric-card {
  background-color: #1e293b;
  border-radius: 8px;
  padding: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
  flex-grow: 0;
  flex-shrink: 0;
}

.metric-card h3 {
  margin: 0 0 5px 0;
  font-size: 14px;
  color: #94a3b8;
}

.metric-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.metric-title {
  font-size: 14px;
  color: #94a3b8;
}

.metric-icon {
  font-size: 20px;
  color: #3b82f6;
}

.metric-value {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}

.metric-change {
  font-size: 14px;
}

.positive-change {
  color: #4ade80;
}

.negative-change {
  color: #f87171;
}

@media (max-width: 768px) {
  .dashboard-metrics {
    flex-wrap: wrap;
  }

  .metric-card {
    flex: 0 0 calc(50% - 7.5px);
    margin-bottom: 15px;
    width: auto;
  }
}
