.dashboard-header {
  width: 100%;
  padding: 20px 0;
}

.header-content {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.logo-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.logo-link {
  display: flex;
  justify-content: center;
}

.header-logo {
  height: 60px; /* Changed from 40px to 60px (1.5 times larger) */
  width: auto;
  display: block;
}

.dashboard-title {
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  margin: 0;
  text-align: center;
}

@media (max-width: 768px) {
  .dashboard-title {
    font-size: 20px;
  }

  .header-logo {
    height: 45px; /* Adjusted for mobile (1.5 times the original mobile size) */
  }
}
