.top-revenue-ids {
  background-color: #1e293b;
  border-radius: 8px;
  padding: 20px;
  margin-top: 30px;
}

.top-revenue-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  flex-wrap: wrap;
}

.top-revenue-ids h2 {
  font-size: 18px;
  color: #e0e0e0;
  margin: 0;
}

.filter-controls {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}

.filter-group {
  display: flex;
  align-items: center;
}

.filter-group label {
  margin-right: 5px;
  font-size: 14px;
  color: #a0aec0;
}

.chain-select,
.platform-select,
.type-select {
  background-color: #2c3e50;
  color: #e0e0e0;
  border: 1px solid #4a5568;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 14px;
}

.id-list-container {
  overflow-x: auto;
}

.id-list-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 5px;
  table-layout: fixed;
}

.id-list-table th,
.id-list-table td {
  padding: 8px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.id-list-table th.sortable {
  cursor: pointer;
  user-select: none;
}

.id-list-table th.sortable:hover {
  background-color: #2c3e50;
}

.id-list-table th.sortable::after {
  content: '▲';
  display: inline-block;
  margin-left: 5px;
  opacity: 0.5;
}

.id-list-table th.sortable.asc::after {
  content: '▲';
  opacity: 1;
}

.id-list-table th.sortable.desc::after {
  content: '▼';
  opacity: 1;
}

.id-item {
  background-color: #2c3e50;
  transition: background-color 0.3s ease;
}

.id-item:hover {
  background-color: #34495e;
}

.id-rank {
  font-weight: bold;
  width: 30px;
}

.id-tokens a {
  color: inherit;
  text-decoration: none;
}

.id-tokens a:hover {
  text-decoration: underline;
}

.id-revenue {
  font-weight: bold;
  color: #4ade80;
}

@media (max-width: 1024px) {
  tbody {
    font-size: 0.8rem;
  }

  .id-type-header,
  .id-type {
    display: none;
  }

  .id-tokens-header,
  .id-tokens {
    width: 25%;
  }

  .id-platform-header,
  .id-platform,
  .id-chain-header,
  .id-chain,
  .id-tvl-header,
  .id-tvl {
    width: 18%;
  }

  .id-revenue-header,
  .id-revenue {
    width: 21%;
  }
}

@media (max-width: 768px) {
  tbody {
    font-size: 0.7rem;
  }

  .top-revenue-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .filter-controls {
    margin-top: 10px;
    flex-direction: column;
    gap: 10px;
  }

  .id-platform-header,
  .id-platform,
  .id-chain-header,
  .id-chain {
    display: none;
  }

  .id-tokens-header,
  .id-tokens {
    width: 40%;
  }

  .id-tvl-header,
  .id-tvl {
    width: 25%;
  }

  .id-revenue-header,
  .id-revenue {
    width: 35%;
  }
}

.id-list-table th {
  padding: 8px 4px;
  text-align: left;
  font-weight: bold;
  color: #a0aec0;
  border-bottom: 1px solid #4a5568;
  font-size: 0.8rem; /* Reduce the font size for all headers */
}

.id-revenue-header {
  font-size: 0.8rem; /* Further reduce the font size for the Annualized Revenue header */
  white-space: nowrap; /* Prevent wrapping */
}

/* Adjust the width of the revenue column */
.id-revenue-header,
.id-revenue {
  width: 18%;
  min-width: 100px;
}

/* Adjust other column widths to accommodate the revenue column */
.id-rank-header,
.id-rank {
  width: 30px;
}

.export-button {
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.export-button:hover {
  background-color: #2980b9;
}

@media (max-width: 768px) {
  .export-button {
    margin-top: 10px;
    align-self: flex-start;
  }
}
