.historical-tvl-chart {
  margin-bottom: 20px;
}

.chart-controls {
  margin-bottom: 10px;
}

.chart-controls select {
  padding: 5px;
  font-size: 14px;
}

.historical-tvl-chart select {
  padding: 5px;
  font-size: 14px;
  border: 1px solid #444;
  border-radius: 4px;
  background-color: #2a2a2a;
  color: #e0e0e0;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
}

.historical-tvl-chart select:focus {
  outline: none;
  border-color: #4ecdc4;
}

.historical-tvl-chart select option {
  background-color: #2a2a2a;
  color: #e0e0e0;
}

.chart-wrapper {
  position: relative;
}

.chain-selector {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #1e293b;
  border: 1px solid #4a5568;
  border-radius: 6px;
  color: #e2e8f0;
  font-size: 14px;
  padding: 8px 32px 8px 12px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.chain-selector:focus {
  outline: none;
  border-color: #4ecdc4;
  box-shadow: 0 0 0 2px rgba(78, 205, 196, 0.2);
}

.chain-selector:hover {
  border-color: #718096;
}

.chain-selector-wrapper::after {
  content: '\25BC';
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  color: #718096;
  pointer-events: none;
  font-size: 12px;
}

.chain-selector option {
  background-color: #1e293b;
  color: #e2e8f0;
}

.chart-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.chart-header h2 {
  margin: 0 0 10px 0;
  font-size: 18px;
  color: #e0e0e0;
  font-weight: 600;
  width: 100%;
}

.chart-filters {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: auto;
}

.chain-selector-wrapper,
.type-selector-wrapper {
  margin: 5px;
  flex-grow: 1;
  min-width: 120px;
  position: relative;
}

.chain-selector,
.type-selector {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #1e293b;
  border: 1px solid #4a5568;
  border-radius: 6px;
  color: #e2e8f0;
  font-size: 14px;
  padding: 8px 32px 8px 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
}

.chain-selector:focus,
.type-selector:focus {
  outline: none;
  border-color: #4ecdc4;
  box-shadow: 0 0 0 2px rgba(78, 205, 196, 0.2);
}

.chain-selector:hover,
.type-selector:hover {
  border-color: #718096;
}

.chain-selector-wrapper::after,
.type-selector-wrapper::after {
  content: '\25BC';
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  color: #718096;
  pointer-events: none;
  font-size: 12px;
}

.chain-selector option,
.type-selector option {
  background-color: #1e293b;
  color: #e2e8f0;
}

.chart-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.chart-header h2 {
  margin: 0 0 10px 0;
  font-size: 18px;
  color: #e0e0e0;
  font-weight: 600;
  width: 100%;
}

.chain-selector-wrapper {
  position: relative;
}

/* Adjust the chart container to match other charts */
.chart {
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  background-color: #1e293b;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Style for the Line chart to ensure it fits well */
.chart-wrapper canvas {
  max-width: 100%;
  height: auto !important;
}

/* Expanded state styles */
.chart.expanded {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  height: 80vh;
  z-index: 1000;
  background-color: #1e293b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.chart-wrapper.expanded {
  width: 100%;
  height: calc(100% - 60px); /* Adjust for header height */
}

.chart.expanded .chart-header {
  width: 100%;
  margin-bottom: 20px;
}

.chart.expanded .chain-selector-wrapper {
  position: absolute;
  top: 20px;
  right: 20px;
}

/* Add a close button for the expanded state */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #e0e0e0;
  font-size: 24px;
  cursor: pointer;
  z-index: 1001;
}

.close-button:hover {
  color: #ffffff;
}

/* ... (keep all existing styles) ... */

.chart-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.chart-expanded {
  width: 80%;
  height: 80%;
  background-color: #1e293b;
  border-radius: 8px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.chart-expanded .chart-wrapper {
  flex-grow: 1;
  width: 100%;
  height: auto;
}

.chart-expanded .chart-header {
  margin-bottom: 20px;
}

.chart {
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.chart:hover {
  transform: scale(1.02);
}

/* Remove the close button styles as we're using overlay click to close */

/* Adjust styles for expanded view */
.chart-expanded .chart-header {
  padding-right: 20px; /* Add some padding for the expanded view */
}

.chart-expanded .chart-filters {
  right: 20px; /* Adjust the right position in the expanded view */
}

/* ... (keep other existing styles) ... */

/* Responsive adjustments */
@media (max-width: 768px) {
  .chart-header {
    flex-direction: column;
  }
}