@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

body {
  background-color: #0f172a;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
}

.dashboard {
  min-height: 100vh;
  padding: 20px;
}

.dashboard-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}

.main-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(15, 23, 42, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  color: white;
  font-size: 18px;
}

@media (max-width: 768px) {
  .dashboard {
    padding: 10px;
  }
}
