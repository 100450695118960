body,
.App {
  background-color: #121212;
  color: #e0e0e0;
}

nav {
  background-color: #121212;
  padding: 15px 0;
  margin-bottom: 20px;
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  gap: 20px;
}

nav ul li {
  margin: 0;
}

nav ul li a {
  color: #e0e0e0;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  padding: 8px 16px;
  border-radius: 4px;
  transition: all 0.3s ease;
  background-color: rgba(59, 130, 246, 0.1);
}

nav ul li a:hover {
  color: #ffffff;
  background-color: rgba(59, 130, 246, 0.2);
}

nav ul li a.active {
  color: #ffffff;
  background-color: rgba(59, 130, 246, 0.3);
}

@media (max-width: 768px) {
  nav ul {
    flex-direction: column;
    align-items: center;
  }

  nav ul li {
    margin-bottom: 10px;
  }

  nav ul li:last-child {
    margin-bottom: 0;
  }
}
