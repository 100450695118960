.chart-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.charts-row {
  display: flex;
  gap: 20px;
}

.chart-column {
  flex: 1;
  min-width: 0;
}

@media (max-width: 768px) {
  .charts-row {
    flex-direction: column;
  }
}

.charts-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.chart {
  background-color: #1e293b;
  border-radius: 8px;
  padding: 20px;
  height: 400px;
  display: flex;
  flex-direction: column;
}

.chart h2 {
  margin-top: 0;
  margin-bottom: 15px;
  color: #e2e8f0;
  font-size: 16px;
}

.chart-wrapper {
  flex-grow: 1;
  position: relative;
}

@media (max-width: 1200px) {
  .charts-container {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .chart {
    height: 300px;
  }
}

.historical-tvl-chart {
  margin-bottom: 20px;
}

.chart-controls {
  margin-bottom: 10px;
}

.chart-controls select {
  padding: 5px;
  font-size: 14px;
}
