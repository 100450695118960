.bold-label {
  font-weight: 800 !important;
}

.chart {
  width: 100%;
  margin-bottom: 20px;
  background-color: #1e1e1e;
  border-radius: 8px;
  padding: 15px;
  box-sizing: border-box;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.chart-wrapper {
  width: 100%;
  height: 400px;
  position: relative;
  overflow: hidden; /* Hide overflow */
}

.chart h2 {
  margin-top: 0;
  margin-bottom: 15px;
  color: #e0e0e0;
  font-size: 1.2rem;
}

/* Ensure the canvas resizes with its container */
.chart-wrapper canvas {
  max-width: 100%;
  height: 100% !important;
}

/* Add this to ensure x-axis labels are visible */
canvas {
  padding-bottom: 20px;
}

.loading-text,
.error-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 18px;
  color: #888;
}

.chart-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.chart-expanded {
  width: 80%;
  height: 80%;
  background-color: #1e293b; /* Match the original chart background */
  border-radius: 8px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.chart-expanded .chart-wrapper {
  flex-grow: 1;
  width: 100%;
  height: auto;
  background-color: #1e293b; /* Ensure the wrapper also has the correct background */
}

.chart-expanded h2 {
  margin-bottom: 20px;
}

.chart {
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.chart:hover {
  transform: scale(1.02);
}

@media (max-width: 768px) {
  .chart {
    padding: 10px;
  }

  .chart-wrapper {
    height: 300px;
  }

  .chart h2 {
    font-size: 1rem;
  }
}
